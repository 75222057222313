@use "../abstracts/variables" as v;

.main{
    height: fit-content;
    max-width: 2000px;
    margin: auto;
    margin-top: 2rem;
}
.side-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-right: 1px solid v.$techblue;
}

/* Sidebar default styling */
.sidebar {
    transition: width 0.3s ease;
    background-color: v.$grey-transp;
    justify-content: start;
    height: 100%;
    width: 100%;
  }
  
  // .sidebar.open {
  //   width: 100px;
  // }
  
  .sidebar.collapsed {
    width: 50px;
  }
  
  .sidebar.collapsed .nav-link span {
    display: none;
  }
  
  .sidebar.collapsed .nav-link {
    text-align: end;
    padding: 0.5rem 0;
  }
  
  .sidebar.open .nav-link {
    padding: 0.5rem 1rem;
  }

  .toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .section-title {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    padding-left: 15px;
  }
  hr {
    border-color: #e9ecef;
  }
