@use "../abstracts/variables" as v;
@use "../base/typography" as t;

.logo {
  display: block;
  width: 160px;
  margin: auto;
}

img {
  width: 100%;
  margin: auto;
}
.index-hero {
  height: auto;
  margin-top: 1rem;
  border-radius: 15px;
  box-shadow: 2px 2px 20px v.$white;
}
.index-caption{
  text-align: justify;
  // background-color: rgba(0, 0, 0, 0.504);
  color:v.$black;
  font-size:1.1rem;
}

.index-hero-text{
  position: absolute;
  top: 300px;
}
.index-logo{
  max-width: 150px;
  height: 100%;
}

.index-section {
  background: v.$grey-transp;
  border-radius: 15px;
  box-shadow: 2px 2px 20px v.$white;
}
.index-section-img {
  border-radius: 15px;
}
@media only screen and (min-width: 1200px){
  .index-hero-text{
    position: relative;
    top:-450px;
  }
}
@media only screen and (max-width: 991px){
  .index-hero-text{
    position: relative;
    top:-280px;
  }
  .index-caption{
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 767px){
  .index-hero-text{
    position: relative;
    top:-200px;
    background-color: rgba(255, 255, 255, 0.824);
  }
  .index-caption{
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 499px){
  .index-hero-text{
    position: relative;
    top:-150px;
    background-color: rgba(255, 255, 255, 0.824);
  }
  .index-caption{
    font-size: 12px !important;
  }
}