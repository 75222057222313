@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as v;

.banner {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #fff;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.login-container{
  width: 200px;
  margin: auto;
  transform: scale(0.75);
  display: flex;
  flex-direction: column;
}

.frame {
  height: 350px;
}

.document {
  cursor: pointer;
  text-align: justify;
}

.appFrame button {
  display: flex;
  margin: 10px auto;
}

#message-body {
  height: 500px;
  overflow: scroll;
}

.todoAndOrderStyle {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    overflow-y: scroll;
  }

  .todoAndOrderStyle::-webkit-scrollbar {
    display: none;
  }

  .item {
    margin: 10px 0;
    max-width: 350px;
  }
  .input-group-text {
    height: 38px;
  }
.table-container{
  background-color: rgba(141, 215, 255, 0.545);
  padding: 20px;
  border: 2px solid #3498db; /* Soft blue border */
  border-radius: 8px;
}
  /* General Styling for Form Frame */
.formFrame {
  // background: linear-gradient(135deg, #0a0f24, #1b2c4f); /* Subtle blue-black gradient */
  border: 2px solid #3498db; /* Soft blue border */
  border-radius: 8px;
  padding: 15px;
  margin: 10px auto;
  box-shadow: 0px 4px 10px rgba(52, 152, 219, 0.4);
  max-width: 500px;
  transition: all 0.3s ease-in-out;
}

/* Hover Effect */
.formFrame:hover {
  // box-shadow: 0px 6px 15px rgba(52, 152, 219, 0.6);
  transform: scale(1.02);
}

/* Labels */
// label {
//   font-family: "Roboto Mono", monospace;
//   color: #aec6e8; /* Light blue text */
//   font-size: 14px;
//   display: block;
//   margin-bottom: 5px;
// }

  
  .formFrame label {
    font-size: 0.8rem;
    margin-bottom: 0.375em;
  }

  .radio{
    width: 15px;
    filter: drop-shadow(3px 3px 2px v.$techblue);
    border: none;
  }
  
  .comments {
    max-width: 350px;
    margin: 10px auto;
    box-shadow: 0 3px 2px v.$grey;
  }
  .weekday {
    color: v.$white;
  }

  .tips{
      color: v.$techblue;
      font-size: 15px;
      margin-left: 5px;
      font-family: Arial, Helvetica, sans-serif;
  }
  .glow:hover {
    transform: translateY(-6px);
    transform: scale(1.3);
  }
  .star-rating {
    display: flex;
  }
  .star {
    font-size: 20px;
    display: inline-block;
  }
  
  .on {
    color: v.$red;
  }
  .off {
    color: v.$techblue-middle
  }

  .modal-body{
    background-color: v.$techblue-middle-trans;
  }
  .modal-header{
    @include table_gradient;
    color: v.$white;
  }
  .modal-footer{
    @include table_gradient;
    color: v.$white;
  }
.rutine-header{
  @include table_gradient;
    color: v.$white;
}
  .message-input{
    display: flex;
    justify-content: space-between;
    margin: auto;
  }

  .user-registe-form{
    max-width: 600px;
    padding: 30px;
    margin: auto;
    margin-top: 10rem;
}

.overflow {
  height: 400px;
  overflow: scroll;
}
.overflow-spec {
  height: 250px;
  overflow: scroll;
}
.overflow-template {
  height: 200px;
  overflow: scroll;
}
.template_bar{
  width: 95%;
  margin: auto;
  transform: scale(0.75);
}
.overflow-spec::-webkit-scrollbar{
  display: none;
}
.overflow-template::-webkit-scrollbar{
  display: none;
}
.overflow::-webkit-scrollbar {
  display: none;
}
.overflow-registe-form {
  height: 800px;
  overflow: scroll;
}
.overflow-registe-form::-webkit-scrollbar {
  display: none;
}

.editor{
  background-color: v.$techblue-middle-trans;
  height:250px;
}

.toggle-table{
  opacity: 0.05;
}

.modal-fullscreen {
  height: fit-content !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
