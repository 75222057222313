/* App.css */

/* Övergripande stil */
.App {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  /* Veckokontroller */
  .controls {
    text-align: center;
  }
  
  /* Inställningsgrupp för cykel */
  .input-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    // align-items: center;
    justify-content: flex-start;
  }
  
  .input-group label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: white;
  }
  
  .input-group input[type="text"],
  .input-group input[type="number"] {
    padding: 5px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  /* Kalendercontainer */
  .calendar {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Varje vecka renderas som en rad med två kolumner
     (vänster: veckokalender, höger: maträtts-menyn om aktiv) */
  .week-row {
    display: grid;
    grid-template-columns: 3fr 1fr; /* justera för att ändra bredden */
    gap: 20px;
    align-items: start;
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 4px;
    cursor: pointer; /* klicka för att aktivera veckan */
  }
  
  .week-row.active {
    /* Exempel: markera den aktiva veckan med en annan bakgrundsfärg */
    background-color: #e7f4ff;
  }
  
  /* Veckokalenderns stil */
  .week h2 {
    margin: 0 0 10px;
    text-align: center;
  }
  
  /* Rutnät för veckodagar */
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }
  
  /* Enskild dagcell */
  .day-cell {
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #fff;
    min-height: 150px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .day-cell h3 {
    font-size: 16px;
    margin: 0 0 5px;
    text-align: center;
  }
  
  /* Måltidssektioner */
  .meal-section {
    margin-bottom: 10px;
  }
  
  .meal-section strong {
    display: block;
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;
  }
  
  /* Drop-zoner */
  .drop-zone {
    border: 1px dashed #aaa;
    padding: 5px;
    text-align: center;
    min-height: 30px;
    margin-bottom: 5px;
    background-color: #eaeaea;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .drop-zone:hover {
    background-color: #d5d5d5;
  }
  
  /* Maträtts-menyn (visas endast i den aktiva vecka-raden) */
  .dishes-menu {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    max-height: 600px;
    overflow-y: auto;
  }
  
  .dishes-menu h2 {
    text-align: center;
    margin-top: 0;
  }
  
  /* Maträttsobjekt */
  .dish-item {
    border: 1px solid #aaa;
    padding: 8px;
    margin-bottom: 8px;
    background-color: #f1f1f1;
    text-align: center;
    cursor: grab;
    border-radius: 4px;
    user-select: none;
    transition: background-color 0.2s;
  }
  
  .dish-item:hover {
    background-color: #e1e1e1;
  }
  