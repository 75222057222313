@use "../abstracts/variables" as v;
.nav {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 5%;
}
.nav div button {
  all: unset;
  background: transparent;
}
